// /app/javascript/components/backgroundColors.jsx
import React from "react";

export const backgroundColors = [
  { label: "Default", value: "#0d6efd" },
  { label: "Black", value: "black" },
  { label: "White", value: "white" },
  { label: "Red", value: "red" },
  { label: "Green", value: "green" },
  { label: "Blue", value: "blue" },
  { label: "Gray", value: "gray" },
  { label: "Yellow", value: "yellow" },
  { label: "Cyan", value: "cyan" },
  { label: "Magenta", value: "magenta" },
  { label: "Lime", value: "lime" },
  { label: "Silver", value: "silver" },
  { label: "Maroon", value: "maroon" },
  { label: "Olive", value: "olive" },
  { label: "Green", value: "green" },
  { label: "Purple", value: "purple" },
  { label: "Teal", value: "teal" },
  { label: "Navy", value: "navy" },
  { label: "Orange", value: "orange" },
  { label: "Gainsboro", value: "Gainsboro" },
  { label: "Light Gray", value: "LightGray" },
  { label: "Dark Gray", value: "DarkGray" },
  { label: "Dim Gray", value: "DimGray" },
  { label: "Slate Gray", value: "SlateGray" },
  { label: "Light Slate Gray", value: "LightSlateGray" },
  { label: "Dark Slate Gray", value: "DarkSlateGray" },
  { label: "White Smoke", value: "WhiteSmoke" },
  { label: "Light Coral", value: "LightCoral" },
  { label: "Indian Red", value: "IndianRed" },
  { label: "Crimson", value: "Crimson" },
  { label: "Fire Brick", value: "FireBrick" },
  { label: "Dark Red", value: "DarkRed" },
  { label: "Pink", value: "Pink" },
  { label: "Light Pink", value: "LightPink" },
  { label: "Hot Pink", value: "HotPink" },
  { label: "Deep Pink", value: "DeepPink" },
  { label: "Medium Violet Red", value: "MediumVioletRed" },
  { label: "Pale Violet Red", value: "PaleVioletRed" },
  { label: "Coral", value: "Coral" },
  { label: "Tomato", value: "Tomato" },
  { label: "Orange Red", value: "OrangeRed" },
  { label: "Dark Orange", value: "DarkOrange" },
  { label: "Orange", value: "Orange" },
  { label: "Moccasin", value: "Moccasin" },
  { label: "Peach Puff", value: "PeachPuff" },
  { label: "Papaya Whip", value: "PapayaWhip" },
  { label: "Saddle Brown", value: "SaddleBrown" },
  { label: "Sienna", value: "Sienna" },
  { label: "Chocolate", value: "Chocolate" },
  { label: "Peru", value: "Peru" },
  { label: "Tan", value: "Tan" },
  { label: "Burly Wood", value: "BurlyWood" },
  { label: "Wheat", value: "Wheat" },
  { label: "Gold", value: "Gold" },
  { label: "Yellow", value: "Yellow" },
  { label: "Light Yellow", value: "LightYellow" },
  { label: "Lemon Chiffon", value: "LemonChiffon" },
  { label: "Khaki", value: "Khaki" },
  { label: "Pale Golden Rod", value: "PaleGoldenRod" },
  { label: "Dark Khaki", value: "DarkKhaki" },
  { label: "Lawn Green", value: "LawnGreen" },
  { label: "Chartreuse", value: "Chartreuse" },
  { label: "Green Yellow", value: "GreenYellow" },
  { label: "Lime Green", value: "LimeGreen" },
  { label: "Pale Green", value: "PaleGreen" },
  { label: "Light Green", value: "LightGreen" },
  { label: "Medium Spring Green", value: "MediumSpringGreen" },
  { label: "Spring Green", value: "SpringGreen" },
  { label: "Forest Green", value: "ForestGreen" },
  { label: "Sea Green", value: "SeaGreen" },
  { label: "Medium Sea Green", value: "MediumSeaGreen" },
  { label: "Dark Sea Green", value: "DarkSeaGreen" },
  { label: "Olive Drab", value: "OliveDrab" },
  { label: "Dark Olive Green", value: "DarkOliveGreen" },
  { label: "Dark Green", value: "DarkGreen" },
  { label: "Light Cyan", value: "LightCyan" },
  { label: "Pale Turquoise", value: "PaleTurquoise" },
  { label: "Aquamarine", value: "Aquamarine" },
  { label: "Medium Aquamarine", value: "MediumAquamarine" },
  { label: "Turquoise", value: "Turquoise" },
  { label: "Medium Turquoise", value: "MediumTurquoise" },
  { label: "Dark Turquoise", value: "DarkTurquoise" },
  { label: "Light Sea Green", value: "LightSeaGreen" },
  { label: "Cadet Blue", value: "CadetBlue" },
  { label: "Dark Cyan", value: "DarkCyan" },
  { label: "Light Blue", value: "LightBlue" },
  { label: "Powder Blue", value: "PowderBlue" },
  { label: "Sky Blue", value: "SkyBlue" },
  { label: "Light Sky Blue", value: "LightSkyBlue" },
  { label: "Deep Sky Blue", value: "DeepSkyBlue" },
  { label: "Dodger Blue", value: "DodgerBlue" },
  { label: "Cornflower Blue", value: "CornflowerBlue" },
  { label: "Royal Blue", value: "RoyalBlue" },
  { label: "Medium Blue", value: "MediumBlue" },
  { label: "Dark Blue", value: "DarkBlue" },
  { label: "Midnight Blue", value: "MidnightBlue" },
  { label: "Lavender", value: "Lavender" },
  { label: "Thistle", value: "Thistle" },
  { label: "Plum", value: "Plum" },
  { label: "Violet", value: "Violet" },
  { label: "Orchid", value: "Orchid" },
  { label: "Medium Orchid", value: "MediumOrchid" },
  { label: "Dark Orchid", value: "DarkOrchid" },
  { label: "Dark Violet", value: "DarkViolet" },
  { label: "Blue Violet", value: "BlueViolet" },
  { label: "Medium Purple", value: "MediumPurple" },
  { label: "Slate Blue", value: "SlateBlue" },
  { label: "Medium Slate Blue", value: "MediumSlateBlue" },
  { label: "Dark Slate Blue", value: "DarkSlateBlue" },
  { label: "Snow", value: "Snow" },
  { label: "Honey Dew", value: "HoneyDew" },
  { label: "Mint Cream", value: "MintCream" },
  { label: "Azure", value: "Azure" },
  { label: "Alice Blue", value: "AliceBlue" },
  { label: "Ghost White", value: "GhostWhite" },
  { label: "Floral White", value: "FloralWhite" },
  { label: "Old Lace", value: "OldLace" },
  { label: "Ivory", value: "Ivory" },
  { label: "Seashell", value: "Seashell" },
  { label: "Beige", value: "Beige" },
  { label: "Linen", value: "Linen" },
  { label: "Antique White", value: "AntiqueWhite" },
  { label: "Dark Slate Gray", value: "DarkSlateGray" },
  { label: "Dim Gray", value: "DimGray" },
  { label: "Slate Gray", value: "SlateGra" }
];

export const backgroundColorsArray = [
  "#0d6efd",
  "black",
  "white",
  "red",
  "green",
  "blue",
  "gray",
  "yellow",
  "cyan",
  "magenta",
  "lime",
  "silver",
  "maroon",
  "olive",
  "green",
  "purple",
  "teal",
  "navy",
  "orange",
  "Gainsboro",
  "LightGray",
  "DarkGray",
  "DimGray",
  "SlateGray",
  "LightSlateGray",
  "DarkSlateGray",
  "WhiteSmoke",
  "LightCoral",
  "IndianRed",
  "Crimson",
  "FireBrick",
  "DarkRed",
  "Pink",
  "LightPink",
  "HotPink",
  "DeepPink",
  "MediumVioletRed",
  "PaleVioletRed",
  "Coral",
  "Tomato",
  "OrangeRed",
  "DarkOrange",
  "Orange",
  "Moccasin",
  "PeachPuff",
  "PapayaWhip",
  "SaddleBrown",
  "Sienna",
  "Chocolate",
  "Peru",
  "Tan",
  "BurlyWood",
  "Wheat",
  "Gold",
  "Yellow",
  "LightYellow",
  "LemonChiffon",
  "Khaki",
  "PaleGoldenRod",
  "DarkKhaki",
  "LawnGreen",
  "Chartreuse",
  "GreenYellow",
  "LimeGreen",
  "PaleGreen",
  "LightGreen",
  "MediumSpringGreen",
  "SpringGreen",
  "ForestGreen",
  "SeaGreen",
  "MediumSeaGreen",
  "DarkSeaGreen",
  "OliveDrab",
  "DarkOliveGreen",
  "DarkGreen",
  "LightCyan",
  "PaleTurquoise",
  "Aquamarine",
  "MediumAquamarine",
  "Turquoise",
  "MediumTurquoise",
  "DarkTurquoise",
  "LightSeaGreen",
  "CadetBlue",
  "DarkCyan",
  "LightBlue",
  "PowderBlue",
  "SkyBlue",
  "LightSkyBlue",
  "DeepSkyBlue",
  "DodgerBlue",
  "CornflowerBlue",
  "RoyalBlue",
  "MediumBlue",
  "DarkBlue",
  "MidnightBlue",
  "Lavender",
  "Thistle",
  "Plum",
  "Violet",
  "Orchid",
  "MediumOrchid",
  "DarkOrchid",
  "DarkViolet",
  "BlueViolet",
  "MediumPurple",
  "SlateBlue",
  "MediumSlateBlue",
  "DarkSlateBlue",
  "Snow",
  "HoneyDew",
  "MintCream",
  "Azure",
  "AliceBlue",
  "GhostWhite",
  "FloralWhite",
  "OldLace",
  "Ivory",
  "Seashell",
  "Beige",
  "Linen",
  "AntiqueWhite",
  "DarkSlateGray",
  "DimGray",
  "SlateGray",
];
